export default {
  computed: {
    startDate: function() {
      return this.$route.query.startDate || this.statistics.startDate;
    },
    endDate: function() {
      return this.$route.query.endDate || this.statistics.endDate;
    },
    numFilters: function() {
      const filters = ["sort", "startDate", "endDate"];
      return filters.filter((f) => this.$route.query[f]).length;
    },
  },
  watch: {
    "$route.query": function() {
      if (Go.is(this.updateFeed, "Function")) {
        this.updateFeed({ page: 1 });
      }
    },
  },
};
