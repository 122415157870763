<template>
  <div class="ExploreCreators">
    <Spacer num="1" />
    <Title :label="$locale['explore_creators']" :class="{ xpadding: $isMobile || $isTablet }" />
    <Spacer num="1" />
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ExploreCreatorsContent">
        <DynamicScroller class="scroller" :items="items" :min-item-size="400" key-field="_id" page-mode :buffer="1080">
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[item.describe]" :data-index="index">
              <CreatorExploreCard class="ExploreCreatorsCard" :creator="item" />
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>

        <LoadMore :morepage="statistics.hasMore" @more="updateFeed({ page: statistics.page + 1 })" />
        <CardMessage v-if="!$loading && !statistics.total && listed" :message="$locale['nothing_found']" />
      </div>
    </div>
  </div>
</template>

<script>
import FeedList from "@/components/feed/feed.list";
import UserFeed from "@/components/user/UserFeed";
export default {
  mixins: [UserFeed, FeedList],
  components: {
    CreatorExploreCard: () => import("@/components/creator/CreatorExploreCard.vue"),
  },
  data: function() {
    return {
      endpoint: `${process.env.VUE_APP_API_HOST}/creator/explore`,
      storeId: "explore_creators",
      items: [],
      statistics: { total: 0, page: 1 },
      limit: 20,
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.ExploreCreators {
  position: relative;
  .ExploreCreatorsContent {
    position: relative;
  }

  .vue-recycle-scroller__item-wrapper {
    display: flex;
    flex-direction: column;
    gap: $mpadding;
  }
}
</style>
