export default {
  data: function() {
    return {
      listed: false,
      endpoint: null,
      store: Go.store("feed_list"),
      items: [],
      statistics: { totalItems: 0, page: 1, total: 0 },
      limit: 100,
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    updateFeed: async function({ page = 1 } = {}) {
      this.isLoading(true);
      await Go.sleep(250);

      try {
        this.listQuery = Go.objectToQuery(this.$route.query);

        if (this.query) {
          this.listQuery = `${this.listQuery}&${this.query}`;
        }

        this.req = await this.$api.get(`${this.endpoint}?${this.listQuery}&page=${page}&limit=${this.limit}`);
        this.reqSuccess(this.req.data);
      } catch (error) {
        this.reqError(error);
      }

      this.isLoading(false);
    },
    reqSuccess: async function({ statistics = {}, items = [] }) {
      if (Go.is(this.hook, "Function")) {
        const hook = await this.hook({ statistics, items });
        statistics = hook.statistics;
        items = hook.items;
      }

      if (Number(statistics.page) === 1 || this.onePage) {
        this.store.set("statistics", statistics);
        this.store.set("items", items);
        this.statistics = statistics;
        this.items = items;

        if (Go.is(this.loaded, "Function")) {
          this.loaded();
        }

        await Go.sleep(APP_TRANSITION_TIME);
        this.listed = true;
        return;
      }

      this.statistics = statistics;
      this.items = [...this.items, ...items];
      this.store.set("statistics", this.statistics);
      this.store.set("items", this.items);

      await Go.sleep(APP_TRANSITION_TIME);
      this.listed = true;
    },
    reqError: function(data = {}) {
      this.showMessage(data);
    },
    startListController: function() {
      if (this.storeId) {
        this.store = Go.store(`${this.storeId}`);
      }

      if (!this.storeId) console.error("No store id provided");

      if (!this.endpoint) console.error("No endpoint provided");
    },
  },
  mounted: async function() {
    if (this.offList) return;

    this.items = this.store.get("items") || [];
    this.statistics = this.store.get("statistics") || { totalItems: 0 };

    if (this.delay) {
      await Go.sleep(this.delay);
    }

    this.updateFeed({ page: 1 });
  },
  beforeMount: function() {
    this.startListController();
  },
};
